import Modal from 'react-bootstrap/Modal';
import { ButtonPrimary, Icon, ModalClose } from '../../../common/components';

type ConfirmationModalProps = {
  show: boolean;
  onHide: any;
  onYes: Function;
  onNo: Function;
  titleText?: string;
  bodyText?: string;
};

const defaultProps = {
  show: true,
};

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { show } = props;

  return (
    <>
      <Modal animation={false} className={'custom-modal'} show={show} backdrop='static' centered>
        <Modal.Header className='pt-0 pb-0 pr-0'>
          <Modal.Title>{props.titleText}</Modal.Title>
          <ModalClose onClick={() => props.onHide()} />
        </Modal.Header>
        <Modal.Body>
          {props.bodyText && <p>{props.bodyText}</p>}

          {
            <div className='d-flex justify-content-end mt-2'>
              <ButtonPrimary onClick={() => props.onYes()} className={'m-0'} title={'Yes'} route='#' />
              <ButtonPrimary onClick={() => props.onNo()} className={'m-0 ml-2'} title={'No'} route='#' />
            </div>
          }
        </Modal.Body>
      </Modal>
    </>
  );
};

ConfirmationModal.defaultProps = defaultProps;
