// Modules
import { useEffect, useState, useReducer } from 'react';

// Components
import { StyledTitle, FilterRowWrapper, ButtonPrimary } from '../../common/components';
import { AdminMessagesGrid } from './AdminMessagesGrid';

// Utils
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../reducer/gridReducer/gridReducer';

// Modals
import { BlankModal } from '../mymessages/modalTemplates/BlankModal';
import { ConfirmationModal } from '../mymessages/modalTemplates/ConfirmationModal';

import { AdminMessagesWrapper } from './AdminMessagesGridStyles';
import { useMsal } from '@azure/msal-react';
import { globalApiParams } from '../../common/api/globals';
import { toast } from 'react-toastify';
import { deleteMessage, getAdminMessages, publishMessage } from '../../common/api/adminMessagesApi';
import { NewMessageModal } from '../mymessages/modalTemplates/NewMessageModal';
import { UpdateMessageModal } from '../mymessages/modalTemplates/UpdateMessageModal';

export const AdminMessages = () => {
  const [showNewMessageModal, setShowNewMessageModal] = useState<boolean>(false);
  const [showDeleteMessageModal, setShowDeleteMessageModal] = useState<boolean>(false);
  const [showIsDeletedMessageModal, setShowIsDeletedMessageModal] = useState<boolean>(false);
  const [showUpdateMessageModal, setShowUpdateMessageModal] = useState<boolean>(false);
  const [showPublishMessageModal, setShowPublishMessageModal] = useState<boolean>(false);
  const [showIsPublishedMessageModal, setShowIsPublishedMessageModal] = useState<boolean>(false);
  
  
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

  const { instance, accounts } = useMsal();
  
  const username = accounts[0] && accounts[0].username;

  const initialState: IGridReducerState = {
    page: 1,
    currentType: '',
    data: {},
    gridData: [],
    initialSortField: 'startDate',
    initialSortOrder: 'DESC',
    currentSortField: 'startDate',
    currentSortOrder: 'DESC',
    isLoading: false,
    hasMoreData: true,
    selectedCustomerId: "-1",
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  const adminMessagesSuccess = (result: Common.IResult<Array<Api.IAdminMessagesPagedData>>) => {
    if (result && result.success) {
      dispatch({
        type: GRID_ACTIONS.ADD_DATA,
        payload: { data: result.data, page: state.page },
      });
    } else {
      error('getAdminMessages');
    }
    
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
  }

  const loadData = async () => {
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: true });

    const params: Api.IAdminMessagesGridParams = {
      sortField: state.currentSortField,
      sortDir: state.currentSortOrder,
      page: state.page,
      pageSize: globalApiParams.pageSize,
    };

    getAdminMessages(accounts[0], instance, params, adminMessagesSuccess, error);
  };

  useEffect(() => {
    loadData();
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentSortField, state.currentSortOrder, state.page]);

  const handleSortChange = (sort: Common.ISort) => {
    setSelectedRowIndex(-1);
    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: sort });
  };

  const handlePagingChange = () => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  const handleSelectedRowChange = (index: number, item: any) => {
    setSelectedRowIndex(index);
    setSelectedRow(item);
  };

  const deleteMessageSuccess = (result: Common.IResult<Array<boolean>>) => {
    if (result && result.success) {
      // Reload grid
      loadData();
      dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
      setShowIsDeletedMessageModal(true);
    } else {
      error('deletemessage');
    }
  }

  const handleDeleteMessageYes = () => {

    setShowDeleteMessageModal(false);

    const row = selectedRow;
    if (row) {
      deleteMessage(accounts[0], instance, row.id, deleteMessageSuccess, error);
    }
  }

  const handleDelete = (row: any) => {
    setSelectedRowIndex(-1);
    setShowDeleteMessageModal(true);
  };
  

  const handleNewMessage = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setSelectedRowIndex(-1);
    setShowNewMessageModal(true);
  }

  const handleUpdate = (event: any) => {
    setShowUpdateMessageModal(true);
  }

  const publishMessageSuccess = (result: Common.IResult<Array<boolean>>) => {
    if (result && result.success) {
      // Reload grid
      loadData();
      dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
      setShowIsPublishedMessageModal(true);
    } else {
      error('publishmessage');
    }
  }

  const error = (error: string) => {
    const message = `API error calling: ${error}`;
    console.error(message);
    toast.error(message, { position: 'bottom-right' });
    dispatch({ type: GRID_ACTIONS.CHANGE_LOADING, payload: false });
  }

  const handlePublishMessageYes = () => {
    setShowPublishMessageModal(false);

    const row = selectedRow;
    if (row) {
      publishMessage(accounts[0], instance, row.id, publishMessageSuccess, error);
    }
  }

  const handlePublish = (event: any) => {
    setShowPublishMessageModal(true);
  }

  const isUpdateEnabled = selectedRowIndex >= 0;
  const isPublishEnabled = selectedRowIndex >= 0 && selectedRow.status === 'Ready' && selectedRow.updatedBy !== username;

  return (
    <AdminMessagesWrapper>
      {/* Mobile */}
      <div className='adminMessagesMobile'>
        <FilterRowWrapper>
          <StyledTitle>Admin Messages</StyledTitle>
        </FilterRowWrapper>
        <div className='d-flex justify-content-center'>
          <ButtonPrimary title='New message' onClick={handleNewMessage} />
          <ButtonPrimary className='ml-3' title='Update' onClick={handleUpdate} disabled={!isUpdateEnabled} />
          <ButtonPrimary className='ml-3' title='Publish' onClick={handlePublish} disabled={!isPublishEnabled} />
        </div>
      </div>
      {/* Desktop */}
      <div className='adminMessagesDesktop container-fluid'>
        <div className='row'>
          <div className='col-md-12'>
            <FilterRowWrapper>
              <StyledTitle>Admin Messages</StyledTitle>
              <div className='d-flex justify-content-end'>
                <ButtonPrimary title='New message' onClick={handleNewMessage} />
                <ButtonPrimary className='ml-3' title='Update' onClick={handleUpdate} disabled={!isUpdateEnabled} />
                <ButtonPrimary className='ml-3' title='Publish' onClick={handlePublish} disabled={!isPublishEnabled} />
              </div>
            </FilterRowWrapper>
          </div>
        </div>
      </div>

      <AdminMessagesGrid
        gridData={state.gridData}
        isLoading={state.isLoading}
        hasMoreData={state.hasMoreData}
        selectedRowIndex={selectedRowIndex}
        handleSortChange={handleSortChange}
        handlePagingChange={handlePagingChange}
        handleSelectedRowChange={handleSelectedRowChange}
        handleDelete={handleDelete}
        currentSortField={state.currentSortField}
        currentSortOrder={state.currentSortOrder}
      />

      <ConfirmationModal
        show={showDeleteMessageModal}
        onHide={() => {setShowDeleteMessageModal(false)}}
        onYes={() => {handleDeleteMessageYes()}}
        onNo={() => {setShowDeleteMessageModal(false)}}
        titleText={'Do you want to delete this message?'}
      ></ConfirmationModal>

      <BlankModal
        show={showIsDeletedMessageModal}
        onHide={() => {setShowIsDeletedMessageModal(false)}}
        titleText={'Message is deleted from the admin page.'}
        buttonText={'OK'}
      ></BlankModal>

      <NewMessageModal
        show={showNewMessageModal}
        onHide={() => {
          setShowNewMessageModal(false);
          loadData();
        }}
      ></NewMessageModal>

      {isUpdateEnabled && <UpdateMessageModal
        show={showUpdateMessageModal}
        onHide={() => {
          setShowUpdateMessageModal(false);
          if (state.page > 1)
            dispatch({ type: GRID_ACTIONS.CLEAR_FILTERS });
          else
            loadData();
        }}
        updateRow={selectedRow}
      ></UpdateMessageModal>}

      <ConfirmationModal
        show={showPublishMessageModal}
        onHide={() => {setShowPublishMessageModal(false)}}
        onYes={() => {handlePublishMessageYes()}}
        onNo={() => {setShowPublishMessageModal(false)}}
        titleText={'Do you want to publish this message?'}
      ></ConfirmationModal>

      <BlankModal
        show={showIsPublishedMessageModal}
        onHide={() => {setShowIsPublishedMessageModal(false)}}
        titleText={'Message is successfully published in the portal.'}
        buttonText={'OK'}
      ></BlankModal>

    </AdminMessagesWrapper>
  );
};
