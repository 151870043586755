import { useMsal } from '@azure/msal-react';
import { ProfileLogout } from '../../common/components/ProfileLogout/ProfileLogout';

type SignOutButtonProps = {
  showReturnToPageSelected: boolean;
  handleReturnToPageSelection: Function;
};

export const SignOutButton = (props: SignOutButtonProps) => {
  const { instance, accounts } = useMsal();

  const name = accounts[0] && accounts[0].name;

  const handleLogout = async () => {
    const accounts = instance.getAllAccounts();

    if (accounts && accounts[0]) {
      const logoutRequest = {
        account: accounts[0],
      };
      instance.logoutRedirect(logoutRequest);
    }
  };

  const handleReturnToPageSelection = async () => {
    props.handleReturnToPageSelection('Menu');
  };

  return (
    <div className='pt-4 pr-2' style={{ textAlign: 'right' }}>
      <ProfileLogout name={name} handleLogout={handleLogout} showReturnToPageSelected={props.showReturnToPageSelected} handleReturnToPageSelection={handleReturnToPageSelection} />
    </div>
  );
};
