import { useEffect, useState } from 'react';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from '../../authConfig';

import { PageLayout } from '../adauth/PageLayout';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import DraxRenewLogo from '../../assets/images/drax-renew-logo.png';
import { SignInButton } from '../adauth/SignInButton';
import { AdminWrapper } from './AdminStyles';
import AdminLandingPage from '../adminLandingPage';

type ProfileContentProps = {
  roles?: Array<string>;
  handleRoles: (roles: Array<string>) => void;
};

export const ProfileContent = (props: ProfileContentProps) => {
  const { accounts } = useMsal();

  useEffect(() => {
    const roles = (accounts[0] && accounts[0].idTokenClaims && accounts[0].idTokenClaims.roles) ?? [];
    props.handleRoles(roles);

    // TODO - This is for testing only

    // StandardUser Only
    // const rolesTest = [
    //   'StandardUser'
    // ];

    // ReportAdminUser Only
    // const rolesTest = [
    //   'Report.AdminUser'
    // ];

    // ReportSuperAdminUser Only
    // const rolesTest = [
    //   'Report.SuperAdminUser'
    // ];

    // BulkMessagesAdminUserOnly Only
    // const rolesTest = [
    //   'BulkMessages.AdminUser'
    // ];

    // All roles
    // const rolesTest = [
    //   'StandardUser',
    //   'Report.AdminUser',
    //   'Report.SuperAdminUser',
    //   'BulkMessages.AdminUser'
    // ];

    // props.handleRoles(rolesTest);
  }, [accounts]);

  return <></>;
};

export const Admin = () => {
  const [roles, setRoles] = useState<Array<string>>([]);
  const [showReturnToPageSelected, setShowReturnToPageSelected] = useState<boolean>(true);
  const [activeNavigationPage, setActiveNavigationPage] = useState<Common.AdminNavigation>('Menu');

  const handleRoles = (roles: string[]): void => {
    setRoles(roles);
  };

  // Pass in the roles for the page your are on.
  // This will return true if there are roles for other pages
  //
  const hasOtherRoles = (usedRoles: Array<string>) => {
    const count = roles.filter(value => !usedRoles.includes(value));
    return count.length > 0;
  };

  const handleSetNavigationPage = (page: Common.AdminNavigation) => {
    setActiveNavigationPage(page);

    switch (page) {
      case 'Menu': {
        setShowReturnToPageSelected(false);
        break;
      }
      case 'TpiAdmin': {
        if (hasOtherRoles(['Report.SuperAdminUser', 'Report.AdminUser'])) setShowReturnToPageSelected(true);
        else setShowReturnToPageSelected(false);
        break;
      }
      case 'BulkMessage': {
        if (hasOtherRoles(['BulkMessages.AdminUser'])) setShowReturnToPageSelected(true);
        else setShowReturnToPageSelected(false);
        break;
      }
      case 'NotAuthorised': {
        setShowReturnToPageSelected(false);
        break;
      }
      default:
        setShowReturnToPageSelected(false);
    }
  };

  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <PageLayout
        showReturnToPageSelected={showReturnToPageSelected}
        handleReturnToPageSelection={handleSetNavigationPage}
      >
        <AuthenticatedTemplate>
          <AdminLandingPage
            roles={roles}
            activeNavigationPage={activeNavigationPage}
            handleSetNavigationPage={handleSetNavigationPage}
          />
          <ProfileContent handleRoles={handleRoles} />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <AdminWrapper>
            <img className='pl-2 pt-2' src={DraxRenewLogo} />
            <div className='text-center'>
              <SignInButton />
            </div>
          </AdminWrapper>
        </UnauthenticatedTemplate>
      </PageLayout>
    </MsalProvider>
  );
};
