import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { Button } from 'react-bootstrap';

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = async () => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.error(e);
    });
  };

  return (
    <div className='pt-4 pl-4'>
      <h3 className='pb-4' style={{ paddingTop: '200px' }}>
        Please Login
      </h3>
      <Button variant='primary' className='ml-auto' onClick={() => handleLogin()}>
        Login with DRAX SSO
      </Button>
    </div>
  );
};
