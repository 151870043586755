// Components
import { ButtonPrimary, Icon } from '../../../common/components';

// Utils
import useVisible from '../../../hooks/useVisibile';
import { iconsToUse } from '../../../common/styles/themes';
import * as variables from '../../../common/styles/variables';
import { ProfileLogoutWrapper } from './ProfileLogout.styles';

type ProfileLogoutProps = {
  name?: string;
  handleLogout: Function;
  showReturnToPageSelected: boolean;
  handleReturnToPageSelection: Function;
};

export const ProfileLogout = (props: ProfileLogoutProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useVisible(false);

  const handleShow = (event: any) => {
    event.preventDefault();
    setIsComponentVisible(!isComponentVisible);
  };

  const handleReturnToPageSelection = () => {
    setIsComponentVisible(false);
    props.handleReturnToPageSelection();
  }

  return (
    <ProfileLogoutWrapper>
      <div className='pl-4 pr-3 pt-2'>{props.name}</div>
      <div ref={ref} className={isComponentVisible ? 'icon-circle-help' : 'icon-circle'}>
        <a href='/' onClick={handleShow} className='col-auto p-3 icon-help'>
          <Icon
            className={isComponentVisible ? 'active' : ''}
            size={variables.iconSizeExtraSmall}
            icon={iconsToUse.profile}
          />
        </a>

        <div className={`profile-popup ${isComponentVisible ? '' : 'hidden'}`}>
          {props.showReturnToPageSelected && 
            <ButtonPrimary className={'btnreturntopageselection'} title={'Return to page selection'} onClick={() => handleReturnToPageSelection()} />
          }
          <ButtonPrimary className={'btnsignout'} title={'Sign out'} onClick={() => props.handleLogout()} />
        </div>
      </div>
    </ProfileLogoutWrapper>
  );
};
