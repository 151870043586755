import { useMsal } from '@azure/msal-react';
import convertArrayToCSV from 'convert-array-to-csv';
import moment from 'moment';
import { useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import { StyledTitle } from '../../common/components';
import { TableFilterOption } from '../../common/components/TableFilter/TableFilter';
import { downloadCSV } from '../../helpers/downloadCSV';
import { useAuthState } from '../../providers/authProvider';
import { gridReducer, GRID_ACTIONS, IGridReducerState } from '../../reducer/gridReducer/gridReducer';
import TpiUserDetailsGrid from './TpiUserDetailsGrid';
import TpiUserDetailsHeader, { INewTpiUser } from './TpiUserDetailsHeader';

import { loginRequest } from '../../authConfig';
import { globalApiParams } from '../../common/api/globals';

import { RequestFailedModal } from '../mymessages/modalTemplates/RequestFailedModal';
import { ConfirmationModal } from '../mymessages/modalTemplates/ConfirmationModal';
import { BlankModal } from '../mymessages/modalTemplates/BlankModal';

type TPIUserDetailsProps = {
  roles?: Array<string>;
};

const defaultProps = {
  roles: [],
};

const TPIUserDetails = (props: TPIUserDetailsProps) => {
  const authContext = useAuthState();
  const [userOptions, setUserOptions] = useState<Common.IExpandedTpiUserOption[]>([]);
  const [selectedTPI, setSelectedTPI] = useState<Api.ITpi>();
  const [selectedTPIDetails, setSelectedTPIDetails] = useState<Api.ITpiDetail>();
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [checkedRows, setCheckedRows] = useState<Array<number>>();
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [addUserError, setAddUserError] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const [showRequestFailedModal, setShowRequestFailedModal] = useState<boolean>(false);
  const [passwordResetConfirmationModal, setPasswordResetConfirmationModal] = useState<boolean>(false);
  const [passwordResetSuccessModal, setPasswordResetSuccessModal] = useState<boolean>(false);
  const [selectedItemForPasswordReset, setSelectedItemForPasswordReset] = useState<Api.ITpiUser | null>(null);

  const { instance, accounts, inProgress } = useMsal();

  const initialState: IGridReducerState = {
    page: 1,
    currentType: 'All',
    data: {},
    initialSortField: 'lastName',
    initialSortOrder: 'ASC',
    currentSortField: 'lastName',
    currentSortOrder: 'ASC',
    selectedCustomerId: '',
    hasMoreData: true,
  };

  const [state, dispatch] = useReducer(gridReducer, initialState);

  const loadData = () => {
    setIsLoading(true);

    const tpiReference = selectedTPIDetails ? selectedTPIDetails?.['tpiReference'] : '';

    const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };
    getUsers(tpiReference, state.currentType, currentSort);
  };

  useEffect(() => {
    loadData();
    // TODO:  Might want to remove this and revisit this problem later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentType, state.page, state.currentSortField, state.currentSortOrder]);

  const clearData = () => {
    setSelectAll(false);
    dispatch({ type: GRID_ACTIONS.CLEAR_FILTERS });
    dispatch({ type: GRID_ACTIONS.CLEAR_DATA });
  };

  // Handlers
  const handleTPIChanged = async (item: Common.IExpandedTpiUserOption) => {
    clearData();

    if (!item) {
      // Clear
      setSelectedTPI(undefined);
      setSelectedTPIDetails(undefined);
      return;
    }

    const tpiReference = item.value;

    setIsLoading(true);

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then(async (response: any) => {
        var headers = new Headers();
        var bearer = 'Bearer ' + response.idToken;
        headers.append('Authorization', bearer);
        var options = {
          method: 'GET',
          headers: headers,
        };

        const url = process.env.REACT_APP_API_SERVICES_URL;

        var allTpisEndpoint = `${url}/tpis/getTpiByTpiReference/${tpiReference}`;

        fetch(allTpisEndpoint, options)
          .then(response => {
            if (response.status === 200) {
              return response.json();
            }
          })
          .then((tpiDetail: Common.IResult<Api.ITpiDetail>) => {
            if (tpiDetail && tpiDetail.success) {
              const data = tpiDetail.data;
              data.status = item.enabled ? 'Enabled' : 'Disabled';

              const tpi: Api.ITpi = {
                id: item.id,
                description: item.description,
                tpiReference: tpiReference,
                enabled: item.enabled,
              };
              setSelectedTPI(tpi);
              setSelectedTPIDetails(data);

              const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };

              getUsers(data['tpiReference'], state.currentType, currentSort);
            } else {
              const message = 'API error calling: getTpiByTpiReference';
              console.error(message);
              toast.error(message, { position: 'bottom-right' });
              dispatch({ type: GRID_ACTIONS.CLEAR_DATA });
            }

            setIsLoading(false);
          });
      })
      .catch((e: any) => {
        const message = 'API error calling: getTpiByTpiReference';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
        setIsLoading(false);
      });
  };

  const getUsers = async (tpiReference: string, type: string, sort?: Common.ISort) => {
    if (!tpiReference) {
      setIsLoading(false);
      return;
    }

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then(async (response: any) => {
        var headers = new Headers();
        var bearer = 'Bearer ' + response.idToken;
        headers.append('Authorization', bearer);
        var options = {
          method: 'GET',
          headers: headers,
        };

        const url = process.env.REACT_APP_API_SERVICES_URL;

        const params: Api.ITpiGridParams = {
          sortField: state.currentSortField,
          sortDir: state.currentSortOrder === 'ASC' ? 1 : 2,
          tpiReference,
          status: type,
          page: state.page,
          pageSize: globalApiParams.pageSize,
        };

        const allTpiUsersEndpoint = `${url}/tpis/getAllTpiUsers?tpiReference=${params.tpiReference}&status=${params.status}&page=${params.page}&pageSize=${params.pageSize}&sortDir=${params.sortDir}&sortField=${params.sortField}`;

        fetch(allTpiUsersEndpoint, options)
          .then(response => {
            if (response.status === 200) {
              return response.json();
            }
          })
          .then((tpiUserResult: Common.IResult<Array<Api.ITpiUser>>) => {
            if (tpiUserResult && tpiUserResult.success) {
              tpiUserResult.data.map(item => {
                // Set UpdatedDate tp CreatedDate if it hasn't been set
                if (!item.updatedDate) item.updatedDate = item.createdDate;

                // Update Status
                if (item.status === 'Disabled') return;

                const isLocked: boolean = item.lockoutEnd != null && moment.utc(item.lockoutEnd).isAfter(moment.utc());
                if (isLocked) {
                  console.log(`Enable: tpiRef: ${item.tpiReference}, email: ${item.email}`);
                  item.status = 'Locked';
                }
              });

              dispatch({
                type: GRID_ACTIONS.ADD_DATA,
                payload: { data: tpiUserResult.data, page: state.page },
              });

              setIsLoading(false);
            } else {
              const message = 'API error calling: getAllTpiUsers';
              console.error(message);
              toast.error(message, { position: 'bottom-right' });
              setIsLoading(false);
            }
          });
      })
      .catch((e: any) => {
        const message = 'API error calling: getAllTpiUsers';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
        setIsLoading(false);
      });
  };

  const handleEnableTPI = async (tpiReference: string) => {
    if (!selectedTPI) return;

    setIsLoading(true);

    console.log(`Enable TPI: ${tpiReference}`);

    const tpi = selectedTPI;
    tpi.enabled = true;

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance.acquireTokenSilent(request).then(async (response: any) => {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      var bearer = 'Bearer ' + response.idToken;
      headers.append('Authorization', bearer);
      var options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(tpi),
      };

      const url = process.env.REACT_APP_API_SERVICES_URL;

      var updateTpisEndpoint = `${url}/tpis/updateTpi?id=${tpi.id}`;

      fetch(updateTpisEndpoint, options)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((tpiUserResult: Common.IResult<number>) => {
          if (tpiUserResult && tpiUserResult.success) {
            // enable in dropdown
            userOptions.map((option: Common.IExpandedTpiUserOption) => {
              if (option.id === tpi.id) {
                option.enabled = true;

                if (selectedTPIDetails) {
                  setSelectedTPIDetails({
                    ...selectedTPIDetails,
                    status: 'Enabled',
                  });
                }
              }
            });
          }

          setIsLoading(false);
        })
        .catch((e: any) => {
          const message = 'API error calling: updateTpi';
          console.error(message);
          toast.error(message, { position: 'bottom-right' });
          setIsLoading(false);
        });
    });
  };

  const handleDisableTPI = async (tpiReference: string) => {
    if (!selectedTPI) return;

    setIsLoading(true);

    const tpi = selectedTPI;
    tpi.enabled = false;

    console.log(`Disable TPI: ${tpiReference}`);

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance.acquireTokenSilent(request).then(async (response: any) => {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      var bearer = 'Bearer ' + response.idToken;
      headers.append('Authorization', bearer);
      var options = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(tpi),
      };

      const url = process.env.REACT_APP_API_SERVICES_URL;

      var updateTpisEndpoint = `${url}/tpis/updateTpi?id=${tpi.id}`;

      fetch(updateTpisEndpoint, options)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then(async (tpiUserResult: Common.IResult<number>) => {
          if (tpiUserResult && tpiUserResult.success) {
            // Disable users
            if (selectedTPIDetails?.tpiReference) {
              await disableAllTpiUsers(selectedTPIDetails!.tpiReference);
            }
          }
        })
        .catch((e: any) => {
          const message = 'API error calling: updateTpi';
          console.error(message);
          toast.error(message, { position: 'bottom-right' });
          setIsLoading(false);
        });
    });
  };

  const disableAllTpiUsers = async (tpiReference: string) => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance.acquireTokenSilent(request).then(async (response: any) => {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      var bearer = 'Bearer ' + response.idToken;
      headers.append('Authorization', bearer);
      var options = {
        method: 'POST',
        headers: headers,
      };

      const url = process.env.REACT_APP_API_SERVICES_URL;

      var disableUsersByReferenceEndpoint = `${url}/tpis/disablealltpiusers/${tpiReference}`;

      fetch(disableUsersByReferenceEndpoint, options)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((disableResult: Common.IResult<number>) => {
          if (disableResult && disableResult.success) {
            if (selectedTPI?.tpiReference) {
              // Load grid
              setCheckedRows([]);
              const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };

              clearData();

              getUsers(selectedTPI?.tpiReference, state.currentType, currentSort);

              // disable in dropdown
              userOptions.map((option: Common.IExpandedTpiUserOption) => {
                if (option.id === selectedTPI.id) {
                  option.enabled = false;

                  if (selectedTPIDetails) {
                    setSelectedTPIDetails({
                      ...selectedTPIDetails,
                      status: 'Disabled',
                    });
                  }
                }
              });
            }
          }
        })
        .catch((e: any) => {
          const message = 'API error calling: disablealltpiusers';
          console.error(message);
          toast.error(message, { position: 'bottom-right' });
          setIsLoading(false);
        });
    });
  };

  const handleDownloadTPIInfoReport = async (tpiReference: string, includeOnlyEnabledTPIs: boolean) => {
    setIsLoading(true);

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then(async (response: any) => {
        var headers = new Headers();
        var bearer = 'Bearer ' + response.idToken;
        headers.append('Authorization', bearer);
        var options = {
          method: 'GET',
          headers: headers,
        };

        const url = process.env.REACT_APP_API_SERVICES_URL;

        var tpiReportEndpoint = `${url}/tpis/tpireport?enabledOnly=${includeOnlyEnabledTPIs}`;
        fetch(tpiReportEndpoint, options)
          .then(response => {
            if (response.status === 200) {
              return response.json();
            }
          })
          .then((tpiInfoResult: Common.IResult<any>) => {
            if (tpiInfoResult && tpiInfoResult.success) {
              const data: any = tpiInfoResult.data;
              const csv = convertArrayToCSV(data);
              const result = csv.toString();

              const fileName = `TPIInfoReport`;

              // Download file
              downloadCSV(result, fileName);
            }
          });
      })
      .catch((e: any) => {
        const message = 'API error calling: tpireport';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
      });

    setIsLoading(false);
  };

  const handleDownloadTPIUserReport = async (tpiReference: string) => {
    setIsLoading(true);

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then(async (response: any) => {
        var headers = new Headers();
        var bearer = 'Bearer ' + response.idToken;
        headers.append('Authorization', bearer);
        var options = {
          method: 'GET',
          headers: headers,
        };

        const url = process.env.REACT_APP_API_SERVICES_URL;

        var tpiUsersReportEndpoint = `${url}/tpis/tpiusersreport`;
        fetch(tpiUsersReportEndpoint, options)
          .then(response => {
            if (response.status === 200) {
              return response.json();
            }
          })
          .then((tpiInfoResult: Common.IResult<any>) => {
            if (tpiInfoResult && tpiInfoResult.success) {
              const data: any = tpiInfoResult.data;
              const csv = convertArrayToCSV(data);
              const result = csv.toString();

              const fileName = `TPIUserReport`;

              // Download file
              downloadCSV(result, fileName);
            }
          });
      })
      .catch((e: any) => {
        const message = 'API error calling: tpiusersreport';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
      });

    setIsLoading(false);
  };

  const clearSelected = () => {
    setSelectedRow(null);
    setCheckedRows([]);
    setSelectAll(false);
  };

  const handleStatusChange = (el: TableFilterOption) => {
    clearSelected();

    dispatch({ type: GRID_ACTIONS.CHANGE_TYPE, payload: el.value });
  };

  const handleSortChange = (sort: Common.ISort) => {
    clearSelected();

    dispatch({ type: GRID_ACTIONS.CHANGE_SORT, payload: sort });
  };

  const handlePagingChange = (item: any) => {
    dispatch({ type: GRID_ACTIONS.INCREMENT_PAGE });
  };

  const handleAddUser = async (user: INewTpiUser) => {
    setShowModal(true);

    setIsLoading(true);
    setAddUserError('');

    const tpiReference = selectedTPIDetails ? selectedTPIDetails?.['tpiReference'] : '';

    if (tpiReference.length <= 0) return;

    console.log(`Add User: ${user.email}, ${user.firstname}, ${user.surname}, ${tpiReference}`);

    const data: Api.ITpiNewUserParams = {
      tpiReference,
      email: user.email,
      firstname: user.firstname,
      lastName: user.surname,
      emailConfirmed: true,
    };

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance.acquireTokenSilent(request).then(async (response: any) => {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      var bearer = 'Bearer ' + response.idToken;
      headers.append('Authorization', bearer);
      var options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data),
      };

      const url = process.env.REACT_APP_API_SERVICES_URL;

      var updateTpisEndpoint = `${url}/identityops/createtpiidentity`;

      fetch(updateTpisEndpoint, options)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((tpiUserResult: Common.IResult<boolean>) => {
          if (tpiUserResult && tpiUserResult.success) {
            clearData();

            // hide modal
            setShowModal(false);

            // Reload grid
            const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };
            getUsers(tpiReference, state.currentType, currentSort);
          } else {
            if (tpiUserResult.errors) {
              tpiUserResult.errors.forEach((error: string) => {
                if (error === 'DuplicateEmail') {
                  setAddUserError(error);
                  return;
                } else if (error === 'DuplicateUserName') {
                  setAddUserError(error);
                  return;
                }
              });
            }

            const message = 'Error creating Tpi User';
            console.error(message);
            toast.error(message, { position: 'bottom-right' });
          }

          setIsLoading(false);
        })
        .catch((e: any) => {
          const message = 'API error calling: createtpiidentity';
          console.error(message);
          toast.error(message, { position: 'bottom-right' });
          setIsLoading(false);
        });
    });
  };

  const disableUsers = async (userIds: Array<string>) => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance.acquireTokenSilent(request).then(async (response: any) => {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      var bearer = 'Bearer ' + response.idToken;
      headers.append('Authorization', bearer);
      var options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(userIds),
      };

      const url = process.env.REACT_APP_API_SERVICES_URL;

      var updateTpisEndpoint = `${url}/tpis/disable`;

      fetch(updateTpisEndpoint, options)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((disableResult: Common.IResult<number>) => {
          if (disableResult && disableResult.success) {
            if (selectedTPI?.tpiReference) {
              // Load grid
              setCheckedRows([]);
              const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };

              clearData();

              getUsers(selectedTPI?.tpiReference, state.currentType, currentSort);
            }
          }

          setIsLoading(false);
        })
        .catch((e: any) => {
          const message = 'API error calling: disable';
          console.error(message);
          toast.error(message, { position: 'bottom-right' });
          setIsLoading(false);
        });
    });
  };

  const handleDisable = async (item: any) => {
    setIsLoading(true);
    let userIds: Array<string> = [];

    if (selectedRow != null) {
      if (state.gridData) {
        const item = state.gridData[selectedRow];
        if (item.status !== 'Disabled') {
          console.log(`Disable: tpiRef: ${item.tpiReference}, email: ${item.email}`);
          userIds.push(item.userId);
        }
      }
    } else {
      checkedRows?.map((index: number) => {
        if (state.gridData) {
          const item = state.gridData[index];
          if (item.status !== 'Disabled') {
            console.log(`Disable: tpiRef: ${item.tpiReference}, email: ${item.email}`);
            userIds.push(item.userId);
          }
        }
      });
    }

    setSelectAll(false);

    await disableUsers(userIds);

    setIsLoading(false);
  };

  const handleEnable = async (item: any) => {
    setIsLoading(true);
    let userIds: Array<string> = [];

    if (selectedRow != null) {
      if (state.gridData) {
        const item = state.gridData[selectedRow];
        const isLocked: boolean = item.lockoutEnd != null && moment.utc(item.lockoutEnd).isAfter(moment.utc());

        if (item.status === 'Disabled' || isLocked) {
          console.log(`Enable: tpiRef: ${item.tpiReference}, email: ${item.email}`);
          userIds.push(item.userId);
        }
      }
    } else {
      checkedRows?.map((index: number) => {
        if (state.gridData) {
          const item = state.gridData[index];

          const isLocked: boolean = item.lockoutEnd != null && moment.utc(item.lockoutEnd).isAfter(moment.utc());

          if (item.status === 'Disabled' || isLocked) {
            console.log(`Enable: tpiRef: ${item.tpiReference}, email: ${item.email}`);
            userIds.push(item.userId);
          }
        }
      });
    }

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance.acquireTokenSilent(request).then(async (response: any) => {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      var bearer = 'Bearer ' + response.idToken;
      headers.append('Authorization', bearer);
      var options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(userIds),
      };

      const url = process.env.REACT_APP_API_SERVICES_URL;

      var updateTpisEndpoint = `${url}/tpis/enable`;

      fetch(updateTpisEndpoint, options)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((enableResult: Common.IResult<number>) => {
          if (enableResult && enableResult.success) {
            if (selectedTPI?.tpiReference) {
              // Load grid
              setCheckedRows([]);
              setSelectAll(false);
              const currentSort: Common.ISort = { field: state.currentSortField, order: state.currentSortOrder };

              clearData();

              getUsers(selectedTPI?.tpiReference, state.currentType, currentSort);
            }
          }

          setIsLoading(false);
        })
        .catch((e: any) => {
          const message = 'API error calling: enable';
          console.error(message);
          toast.error(message, { position: 'bottom-right' });
          setIsLoading(false);
        });
    });
  };

  const handleGeneratePasswordReset = async (item: Api.ITpiUser) => {
    setPasswordResetConfirmationModal(true);
    setSelectedItemForPasswordReset(item);
  };

  const handleStatusClick = (item: Api.ITpiUser) => {
    // Do nothing, it's a badge not a button
    // console.log(`Status Button Clicked: ${item.status}, Tpi Reference: ${item.tpiReference}, Email: ${item.email}`);
  };

  const handleRowChecked = (index: number, checkedItems: Array<number>) => {
    setSelectedRow(null);
    setCheckedRows(checkedItems);
  };

  const handleRowSelected = (index: number) => {
    setSelectedRow(index);
  };

  const handleSelectAll = (checkedItems: Array<number>) => {
    setSelectedRow(null);
    setCheckedRows(checkedItems);
    setSelectAll(!selectAll);
  };

  const getData = async () => {
    setIsLoading(true);

    const users: Array<Common.IExpandedTpiUserOption> = [];

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then(async (response: any) => {
        var headers = new Headers();
        var bearer = 'Bearer ' + response.idToken;
        headers.append('Authorization', bearer);
        var options = {
          method: 'GET',
          headers: headers,
        };

        const url = process.env.REACT_APP_API_SERVICES_URL;

        var allTpisEndpoint = `${url}/tpis/getAllTpis`;

        fetch(allTpisEndpoint, options)
          .then(response => {
            if (response.status === 200) {
              return response.json();
            }
          })
          .then((tpisResult: Common.IResult<Array<Api.ITpi>>) => {
            if (tpisResult && tpisResult.success) {
              tpisResult.data?.map((item: Api.ITpi) => {
                let option: Common.IExpandedTpiUserOption = {
                  id: item.id,
                  value: item.tpiReference,
                  display: `${item.description} - ${item.enabled === true ? 'Enabled' : 'Disabled'}`,
                  label: `${item.tpiReference} - ${item.description}`,
                  enabled: item.enabled,
                  formattedDisplay: `${item.tpiReference} - ${item.description}`,
                  description: item.description,
                };
                users.push(option);
              });
            }
          });
      })
      .catch((e: any) => {
        const message = 'API error calling: getAllTpis';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
      });

    setUserOptions(users);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const generatePasswordReset = async () => {
    setIsLoading(true);

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then(async (response: any) => {
        var headers = new Headers();
        var bearer = 'Bearer ' + response.idToken;
        headers.append('Authorization', bearer);
        var options = {
          method: 'GET',
          headers: headers,
        };

        const url = process.env.REACT_APP_API_SERVICES_URL;

        var item = selectedItemForPasswordReset;
        if (!item) throw 'User not selected';

        const userId = item.userId;

        var generatepasswordresetlinkEndpoint = `${url}/identityops/generatepasswordresetlink/${userId}`;

        fetch(generatepasswordresetlinkEndpoint, options)
          .then(response => {
            setIsLoading(false);
            if (response.status === 200) {
              return response.json();
            }
          })
          .then((generatepasswordresetlinkResult: Common.IResult<boolean>) => {
            if (generatepasswordresetlinkResult && generatepasswordresetlinkResult.success) {
              setPasswordResetSuccessModal(true);
            } else {
              setShowRequestFailedModal(true);
              setSelectedItemForPasswordReset(null);
            }
          })
          .catch((e: any) => {
            setShowRequestFailedModal(true);
            setSelectedItemForPasswordReset(null);
            setIsLoading(false);

            const message = 'API error calling: generatepasswordresetlink';
            console.error(message);
            toast.error(message, { position: 'bottom-right' });
          });
      })
      .catch((e: any) => {
        setShowRequestFailedModal(true);
        setSelectedItemForPasswordReset(null);
        setIsLoading(false);

        const message = 'API error calling: generatepasswordresetlink';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
      });
  };

  const isTpiAdminSuperUser =
    props.roles?.indexOf('Report.SuperAdminUser') && props.roles?.indexOf('Report.SuperAdminUser') >= 0;

  return (
    <>
      <StyledTitle className='p-4'>Third Party Intermediary - User Admin Page</StyledTitle>
      <br />
      <div className='pl-4'>
        <TpiUserDetailsHeader
          {...selectedTPIDetails}
          options={userOptions}
          handleTPIChanged={handleTPIChanged}
          handleEnableTPI={handleEnableTPI}
          handleDisableTPI={handleDisableTPI}
          handleDownloadTPIInfoReport={handleDownloadTPIInfoReport}
          handleDownloadTPIUserReport={handleDownloadTPIUserReport}
          handleStatusChange={handleStatusChange}
          handleAddUser={handleAddUser}
          handleDisable={handleDisable}
          handleEnable={handleEnable}
          handleGeneratePasswordReset={handleGeneratePasswordReset}
          tpiAdminSuperUser={isTpiAdminSuperUser == true}
          gridData={state.gridData}
          checkedItems={checkedRows}
          selectedRow={selectedRow}
          error={addUserError}
          showModal={showModal}
        ></TpiUserDetailsHeader>
        <TpiUserDetailsGrid
          tpiDetails={selectedTPIDetails}
          handleSortChange={handleSortChange}
          handlePagingChange={handlePagingChange}
          handleStatusClick={handleStatusClick}
          handleRowChecked={handleRowChecked}
          handleRowSelected={handleRowSelected}
          handleSelectAll={handleSelectAll}
          gridData={state.gridData}
          hasMoreData={state.hasMoreData}
          checkedItems={checkedRows}
          selectedRow={selectedRow}
          selectAll={selectAll}
          isLoading={isLoading}
          currentSortField={state.currentSortField}
          currentSortOrder={state.currentSortOrder}
        ></TpiUserDetailsGrid>
      </div>

      <RequestFailedModal
        show={showRequestFailedModal}
        onHide={() => {
          setShowRequestFailedModal(false);
        }}
      />
      <ConfirmationModal
        show={passwordResetConfirmationModal}
        onHide={() => {
          setPasswordResetConfirmationModal(false);
        }}
        onYes={() => {
          setPasswordResetConfirmationModal(false);

          generatePasswordReset();
        }}
        onNo={() => {
          setPasswordResetConfirmationModal(false);
          setSelectedItemForPasswordReset(null);
        }}
        titleText={'Do you want to send password reset email?'}
        bodyText={''}
      />
      <BlankModal
        show={passwordResetSuccessModal}
        onHide={() => {
          setPasswordResetSuccessModal(false);
          setSelectedItemForPasswordReset(null);
        }}
        titleText={`Reset password email link for ${selectedItemForPasswordReset?.email} has been sent successfully.`}
        buttonText={'OK'}
      ></BlankModal>
    </>
  );
};

export default TPIUserDetails;
