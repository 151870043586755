import { NavigationTemplateProps } from './NavigationTemplateProps';

export const internalNavigation = ({ brand }: NavigationTemplateProps) => [
  {
    text: 'Tpi Admin',
    link: '/tpi-admin',
    icon: 'profile',
    classname: '',
    isVisible: true,
  },
];
