import { useIsAuthenticated } from '@azure/msal-react';
import { SignOutButton } from './SignOutButton';

export const PageLayout = (props: React.PropsWithChildren<{showReturnToPageSelected: boolean, handleReturnToPageSelection: Function}>) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      {isAuthenticated ? (
        <SignOutButton showReturnToPageSelected={props.showReturnToPageSelected} handleReturnToPageSelection={props.handleReturnToPageSelection}/>
      ) : (
        <>
          <p className='pl-4 pt-4'>Redirecting to Drax Energy...</p>
        </>
      )}
      {props.children}
    </>
  );
};
