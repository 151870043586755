import { ButtonPrimary, StyledTitle } from '../../common/components';
import TPIUserDetails from '../tpiuserdetails';
import { AdminLandingWrapper } from './AdminLandingPageStyles';
import DraxRenewLogo from '../../assets/images/drax-renew-logo.png';
import { useEffect, useState } from 'react';
import { AdminMessages } from '../adminMessages';
import { UserSearch } from './UserSearch';
import { ResultsGrid } from './ResultsGrid';
import ServiceUpdates from '../serviceupdates/ServiceUpdates';
import { UpdateType } from '../serviceupdates/ServiceUpdateHealth';
import { loginRequest } from '../../authConfig';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import { RequestFailedModal } from '../mymessages/modalTemplates/RequestFailedModal';
import { ConfirmationModal } from '../mymessages/modalTemplates/ConfirmationModal';
import { BlankModal } from '../mymessages/modalTemplates/BlankModal';

type AdminLandingPageProps = {
  roles?: Array<string>;
  activeNavigationPage: Common.AdminNavigation;
  handleSetNavigationPage: (page: Common.AdminNavigation) => void;
};

const defaultProps = {
  roles: [],
  activeNavigationPage: 'Menu',
  handleSetNavigationPage: null,
};

const AdminLandingPage = (props: AdminLandingPageProps) => {
  const [searchResults, setSearchResults] = useState<Api.IAdminUserSearchResult | null>();
  const [serviceMessages, setServiceMessages] = useState<Array<ServiceUpdateType>>();
  const { instance, accounts } = useMsal();
  const [responseError, setResponseError] = useState<boolean>(false);
  const [selectedSearchType, setSelectedSearchType] = useState<string>('');
  const [selectedSearchText, setSelectedSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [passwordResetConfirmationModal, setPasswordResetConfirmationModal] = useState<boolean>(false);
  const [selectedItemForPasswordReset, setSelectedItemForPasswordReset] = useState<Api.IAdminUserSearchResult | null>();
  const [passwordResetSuccessModal, setPasswordResetSuccessModal] = useState<boolean>(false);
  const [showRequestFailedModal, setShowRequestFailedModal] = useState<boolean>(false);

  const isStandardUser = props.roles?.indexOf('StandardUser') != null && props.roles?.indexOf('StandardUser') >= 0;
  const isTpiAdminUser =
    props.roles?.indexOf('Report.AdminUser') != null && props.roles?.indexOf('Report.AdminUser') >= 0;
  const isTpiAdminSuperUser =
    props.roles?.indexOf('Report.SuperAdminUser') != null && props.roles?.indexOf('Report.SuperAdminUser') >= 0;
  const isBulkMessagesAdminUser =
    props.roles?.indexOf('BulkMessages.AdminUser') != null && props.roles?.indexOf('BulkMessages.AdminUser') >= 0;
  const isTpiUser = isTpiAdminUser || isTpiAdminSuperUser;

  useEffect(() => {
    if (isTpiUser && isBulkMessagesAdminUser) {
      props.handleSetNavigationPage('Menu');
    } else if (isTpiUser && !isBulkMessagesAdminUser && !isStandardUser) {
      props.handleSetNavigationPage('TpiAdmin');
    } else if (!isTpiUser && !isStandardUser && isBulkMessagesAdminUser) {
      props.handleSetNavigationPage('BulkMessage');
    } else if (isStandardUser) {
      props.handleSetNavigationPage('Menu');
    } else if (!isTpiUser && !isBulkMessagesAdminUser && !isStandardUser) {
      props.handleSetNavigationPage('NotAuthorised');
    }
  }, [props.roles]);

  useEffect(() => {
    getAllServiceMessages();
  }, []);

  const handleTPIAdmin = () => {
    props.handleSetNavigationPage('TpiAdmin');
  };

  const handlePortalAdminMessages = () => {
    props.handleSetNavigationPage('BulkMessage');
  };

  const handleErrorReset = () => {
    setResponseError(false);
  };

  const handleCheckClick = async (searchType: string, searchText: string) => {
    setIsLoading(true);

    setResponseError(false);
    setSearchResults(null);
    setSelectedSearchText(searchText);
    setSelectedSearchType(searchType);

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance.acquireTokenSilent(request).then(async (response: any) => {
      var headers = new Headers();
      var bearer = 'Bearer ' + response.idToken;
      headers.append('Authorization', bearer);
      var options = {
        method: 'GET',
        headers: headers,
      };

      const url = process.env.REACT_APP_API_SERVICES_URL;

      const entityType = searchType;
      const value = searchText;

      const currentServiceMessages = `${url}/identityops/getuserdetails?entityType=${entityType}&value=${value}`;

      fetch(currentServiceMessages, options)
        .then(response => {
          if (response.status === 204) {
            setResponseError(true);
            return;
          } else if (response.status === 200) {
            return response.json();
          }
        })
        .then((userSearchResult: Api.IAdminUserSearchResult) => {
          if (userSearchResult) {
            setSearchResults(userSearchResult);
          }
          setIsLoading(false);
        })
        .catch((e: any) => {
          const message = 'API error calling: getUserDetails';
          console.error(message);
          toast.error(message, { position: 'bottom-right' });
          setIsLoading(false);
        });
    });
  };

  const handleUnlock = (email: string, userType: string, userId: string) => {
    setIsLoading(true);

    if (userType === 'TPIUser') {
      handleUnlockTpi(userId);
    } else {
      handleUnlockCustomer(email);
    }
  };

  const handleUnlockCustomer = (email: string) => {
    const data: any = {
      userName: email,
      enabled: true,
    };

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then(async (response: any) => {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');

        var bearer = 'Bearer ' + response.idToken;
        headers.append('Authorization', bearer);
        var options = {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(data),
        };

        const url = process.env.REACT_APP_API_SERVICES_URL;

        var enableCustomerEndpoint = `${url}/identityops/enabledisableuser`;

        fetch(enableCustomerEndpoint, options)
          .then(response => {
            if (response.status === 200) {
              return response.json();
            }
          })
          .then((enableCustomerResult: Common.IResult<any>) => {
            if (enableCustomerResult && enableCustomerResult.success) {
              handleCheckClick(selectedSearchType, selectedSearchText);
            }
            setIsLoading(false);
          });
      })
      .catch((e: any) => {
        const message = 'API error calling: enableDisableUser';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
        setIsLoading(false);
      });
  };

  const handleUnlockTpi = (userId: string) => {
    setIsLoading(true);

    let userIds: Array<string> = [];
    userIds.push(userId);

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance.acquireTokenSilent(request).then(async (response: any) => {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');

      var bearer = 'Bearer ' + response.idToken;
      headers.append('Authorization', bearer);
      var options = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(userIds),
      };

      const url = process.env.REACT_APP_API_SERVICES_URL;

      var enableTpiEndpoint = `${url}/tpis/enable`;

      fetch(enableTpiEndpoint, options)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((enableTpiResult: Common.IResult<number>) => {
          if (enableTpiResult && enableTpiResult.success) {
            handleCheckClick(selectedSearchType, selectedSearchText);
          }
          setIsLoading(false);
        })
        .catch((e: any) => {
          const message = 'API error calling: enable';
          console.error(message);
          toast.error(message, { position: 'bottom-right' });
          setIsLoading(false);
        });
    });
  };

  type ServiceUpdateType = {
    page: string;
    type: UpdateType;
    message: string;
  };

  const getAllServiceMessages = () => {
    setIsLoading(true);
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance.acquireTokenSilent(request).then(async (response: any) => {
      var headers = new Headers();
      var bearer = 'Bearer ' + response.idToken;
      headers.append('Authorization', bearer);
      var options = {
        method: 'GET',
        headers: headers,
      };

      const url = process.env.REACT_APP_API_SERVICES_URL;

      const currentServiceMessages = `${url}/content/servicemessages`;

      fetch(currentServiceMessages, options)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((serviceMessagesResult: Common.IResult<Array<Api.IServiceMessageData>>) => {
          if (serviceMessagesResult && serviceMessagesResult.success) {
            const serviceMessageData: Array<any> = serviceMessagesResult.data.map((item: any) => {
              return {
                page: item.serviceMessageName,
                message: item.serviceMessageText,
                type: item.serviceMessageSeverity,
              };
            });
            setServiceMessages(serviceMessageData);
            setIsLoading(false);
          }
        })
        .catch((e: any) => {
          const message = 'API error calling: getAllServiceMessages';
          console.error(message);
          toast.error(message, { position: 'bottom-right' });
          setIsLoading(false);
        });
    });
  };

  const handleGeneratePasswordReset = async (user: Api.IAdminUserSearchResult) => {
    setPasswordResetConfirmationModal(true);
    setSelectedItemForPasswordReset(user);
  };

  const generatePasswordReset = async () => {
    setIsLoading(true);

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then(async (response: any) => {
        var headers = new Headers();
        var bearer = 'Bearer ' + response.idToken;
        headers.append('Authorization', bearer);
        var options = {
          method: 'GET',
          headers: headers,
        };

        const url = process.env.REACT_APP_API_SERVICES_URL;

        const userId = selectedItemForPasswordReset?.id;

        var generatepasswordresetlinkEndpoint = `${url}/identityops/generatepasswordresetlink/${userId}`;

        fetch(generatepasswordresetlinkEndpoint, options)
          .then(response => {
            setIsLoading(false);
            if (response.status === 200) {
              return response.json();
            }
          })
          .then((generatepasswordresetlinkResult: Common.IResult<boolean>) => {
            if (generatepasswordresetlinkResult && generatepasswordresetlinkResult.success) {
              setPasswordResetSuccessModal(true);
            } else {
              setShowRequestFailedModal(true);
              setSelectedItemForPasswordReset(null);
            }
          })
          .catch((e: any) => {
            setShowRequestFailedModal(true);
            setSelectedItemForPasswordReset(null);
            setIsLoading(false);

            const message = 'API error calling: generatepasswordresetlink';
            console.error(message);
            toast.error(message, { position: 'bottom-right' });
          });
      })
      .catch((e: any) => {
        setShowRequestFailedModal(true);
        setSelectedItemForPasswordReset(null);
        setIsLoading(false);

        const message = 'API error calling: generatepasswordresetlink';
        console.error(message);
        toast.error(message, { position: 'bottom-right' });
      });
  };

  return (
    <>
      <AdminLandingWrapper>
        {props.activeNavigationPage == 'Menu' && (
          <>
            <img className='pl-2' src={DraxRenewLogo} />
            <div className='d-flex justify-content-center pt-5'>
              <StyledTitle>My Energy Supply - Internal user admin page</StyledTitle>
            </div>

            <div className='d-flex justify-content-end pr-4'>
              <div className='d-flex'>
                <div className='d-flex flex-column'>
                  <div className='pt-4 d-flex'>
                    {isTpiUser && (
                      <div className=''>
                        <ButtonPrimary route='#' className='' title={'TPI Admin'} onClick={() => handleTPIAdmin()} />
                      </div>
                    )}

                    {isBulkMessagesAdminUser && (
                      <div className='pl-3'>
                        <ButtonPrimary
                          route='#'
                          className=''
                          title={'Portal admin messages'}
                          onClick={() => handlePortalAdminMessages()}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {isStandardUser && (
              <div className='d-flex justify-content-center pt-4'>
                <div className='flex-column search'>
                  <div>
                    <UserSearch
                      handleCheckClick={handleCheckClick}
                      handleErrorReset={handleErrorReset}
                      responseErrorReceived={responseError}
                      isLoading={isLoading}
                    ></UserSearch>
                  </div>
                  {searchResults && (
                    <div className='py-2'>
                      <ResultsGrid
                        gridData={searchResults}
                        handlePasswordReset={handleGeneratePasswordReset}
                        handleUnlock={handleUnlock}
                        isLoading={isLoading}
                      ></ResultsGrid>
                    </div>
                  )}
                  <div className='py-2'>
                    <ServiceUpdates messages={serviceMessages} />
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {props.activeNavigationPage == 'TpiAdmin' && <TPIUserDetails roles={props.roles} />}

        {props.activeNavigationPage == 'BulkMessage' && (
          <div className='p-4'>
            <AdminMessages></AdminMessages>
          </div>
        )}

        {props.activeNavigationPage == 'NotAuthorised' && (
          <div className='p-4'>
            You are not authorised to access any pages, please contact the admin to grant you access.
          </div>
        )}
      </AdminLandingWrapper>

      <RequestFailedModal
        show={showRequestFailedModal}
        onHide={() => {
          setShowRequestFailedModal(false);
        }}
      />
      <ConfirmationModal
        show={passwordResetConfirmationModal}
        onHide={() => {
          setPasswordResetConfirmationModal(false);
        }}
        onYes={() => {
          setPasswordResetConfirmationModal(false);

          generatePasswordReset();
        }}
        onNo={() => {
          setPasswordResetConfirmationModal(false);
          setSelectedItemForPasswordReset(null);
        }}
        titleText={'Do you want to send password reset email?'}
        bodyText={''}
      />
      <BlankModal
        show={passwordResetSuccessModal}
        onHide={() => {
          setPasswordResetSuccessModal(false);
          setSelectedItemForPasswordReset(null);
        }}
        titleText={`Reset password email link for ${selectedItemForPasswordReset?.email} has been sent successfully.`}
        buttonText={'OK'}
      ></BlankModal>
    </>
  );
};

export default AdminLandingPage;
